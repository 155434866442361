import {
    POST_EPAN_INCOMPLETE_DEV_REQUEST,
    POST_EPAN_INCOMPLETE_DEV_SUCCESS,
    POST_EPAN_INCOMPLETE_DEV_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanIncompleteDevList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_INCOMPLETE_DEV_REQUEST:
            return {
                ...state,
                loading: true,
                epanIncompleteDevList: [],
            };
        case POST_EPAN_INCOMPLETE_DEV_SUCCESS:
            return {
                ...state,
                loading: false,
                epanIncompleteDevList: action.data.response,
            };
        case POST_EPAN_INCOMPLETE_DEV_FAILED:
            return {
                ...state,
                loading: false,
                epanIncompleteDevList: [],
            };
        default:
            return state
    }
  }
  
  