import {
    GET_LATEST_SPAN_REQUEST,
    GET_LATEST_SPAN_SUCCESS,
    GET_LATEST_SPAN_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    spanFileList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_LATEST_SPAN_REQUEST:
            return {
                ...state,
                loading: true,
                spanFileList: [],
            };
        case GET_LATEST_SPAN_SUCCESS:
            return {
                ...state,
                loading: false,
                spanFileList: action.data.result[0],
            };
        case GET_LATEST_SPAN_FAILED:
            return {
                ...state,
                loading: false,
                spanFileList: [],
            };
        default:
            return state
    }
  }
  
  