import {
    POST_EPAN_VERIFY_OTP_DEV_REQUEST,
    POST_EPAN_VERIFY_OTP_DEV_SUCCESS,
    POST_EPAN_VERIFY_OTP_DEV_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanVeriftyOtpDevList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_VERIFY_OTP_DEV_REQUEST:
            return {
                ...state,
                loading: true,
                epanVeriftyOtpDevList: [],
            };
        case POST_EPAN_VERIFY_OTP_DEV_SUCCESS:
            return {
                ...state,
                loading: false,
                epanVeriftyOtpDevList: action.data,
            };
        case POST_EPAN_VERIFY_OTP_DEV_FAILED:
            return {
                ...state,
                loading: false,
                epanVeriftyOtpDevList: [],
            };
        default:
            return state
    }
  }
  
  