export const POST_EPAN_WEB_LOGIN_REQUEST = "POST_EPAN_WEB_LOGIN_REQUEST";
export const POST_EPAN_WEB_LOGIN_SUCCESS = "POST_EPAN_WEB_LOGIN_SUCCESS";
export const POST_EPAN_WEB_LOGIN_FAILED = "POST_EPAN_WEB_LOGIN_FAILED";

export const POST_EPAN_WEB_DETAILS_REQUEST = "POST_EPAN_WEB_DETAILS_REQUEST";
export const POST_EPAN_WEB_DETAILS_SUCCESS = "POST_EPAN_WEB_DETAILS_SUCCESS";
export const POST_EPAN_WEB_DETAILS_FAILED = "POST_EPAN_WEB_DETAILS_FAILED";

export const POST_EPAN_WEB_CHECK_AADHAR_KYC_REQUEST = "POST_EPAN_WEB_CHECK_AADHAR_KYC_REQUEST";
export const POST_EPAN_WEB_CHECK_AADHAR_KYC_SUCCESS = "POST_EPAN_WEB_CHECK_AADHAR_KYC_SUCCESS";
export const POST_EPAN_WEB_CHECK_AADHAR_KYC_FAILED = "POST_EPAN_WEB_CHECK_AADHAR_KYC_FAILED";
export const POST_EPAN_WEB_CHECK_AADHAR_KYC_PENDING = "POST_EPAN_WEB_CHECK_AADHAR_KYC_PENDING";

export const EPAN_MAKE_PAYMENT_SUCCESS = "EPAN_MAKE_PAYMENT_SUCCESS";
export const EPAN_MAKE_PAYMENT_REQUEST = "EPAN_MAKE_PAYMENT_REQUEST";
export const EPAN_MAKE_PAYMENT_FAILED = "EPAN_MAKE_PAYMENT_FAILED";

export const EPAN_TRANSACTION_STATUS_REQUEST = "EPAN_TRANSACTION_STATUS_REQUEST";
export const EPAN_TRANSACTION_STATUS_PENDING = "EPAN_TRANSACTION_STATUS_PENDING";
export const EPAN_TRANSACTION_STATUS_SUCCESS = "EPAN_TRANSACTION_STATUS_SUCCESS";
export const EPAN_TRANSACTION_STATUS_FAILED = "EPAN_TRANSACTION_STATUS_FAILED";

export const CHECK_USER_PAYMENT_REQUEST = "CHECK_USER_PAYMENT_REQUEST";
export const CHECK_USER_PAYMENT_FAILED = "CHECK_USER_PAYMENT_FAILED";
export const CHECK_USER_PAYMENT_SUCCESS = "CHECK_USER_PAYMENT_SUCCESS";

export const CHECKPOINT_REQUEST = "CHECKPOINT_REQUEST";
export const CHECKPOINT_SUCCESS = "CHECKPOINT_SUCCESS";
export const CHECKPOINT_FAILED = "CHECKPOINT_FAILED";