import {
  GET_MEDIA_LIST_REQUEST,
  GET_MEDIA_LIST_SUCCESS,
  GET_MEDIA_LIST_FAILED,
} from "../Constants/actionTypes.constants";

export const initialStore = {
  mediaList: [],
  loading: false,
};



export default (state = initialStore, action) => {
  switch (action.type) {
      case GET_MEDIA_LIST_REQUEST:
          return {
              ...state,
              loading: true,
              mediaList: [],
          };
      case GET_MEDIA_LIST_SUCCESS:
          return {
              ...state,
              loading: false,
              mediaList: action.data.data,
          };
      case GET_MEDIA_LIST_FAILED:
          return {
              ...state,
              loading: false,
              mediaList: [],
          };
      default:
          return state
  }
}

