import {
    POST_EPAN_RESEND_UPI_DEV_REQUEST,
    POST_EPAN_RESEND_UPI_DEV_SUCCESS,
    POST_EPAN_RESEND_UPI_DEV_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanResendUPIidDevList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_RESEND_UPI_DEV_REQUEST:
            return {
                ...state,
                loading: true,
                epanResendUPIidDevList: [],
            };
        case POST_EPAN_RESEND_UPI_DEV_SUCCESS:
            return {
                ...state,
                loading: false,
                epanResendUPIidDevList: action.data,
            };
        case POST_EPAN_RESEND_UPI_DEV_FAILED:
            return {
                ...state,
                loading: false,
                epanResendUPIidDevList: [],
            };
        default:
            return state
    }
  }
  
  