import {
    POST_EPAN_PAYMENT_RESEND_REQUEST,
    POST_EPAN_PAYMENT_RESEND_SUCCESS,
    POST_EPAN_PAYMENT_RESEND_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanPaymentResendList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_PAYMENT_RESEND_REQUEST:
            return {
                ...state,
                loading: true,
                epanPaymentResendList: [],
            };
        case POST_EPAN_PAYMENT_RESEND_SUCCESS:
            return {
                ...state,
                loading: false,
                epanPaymentResendList: action.data,
            };
        case POST_EPAN_PAYMENT_RESEND_FAILED:
            return {
                ...state,
                loading: false,
                epanPaymentResendList: [],
            };
        default:
            return state
    }
  }
  
  