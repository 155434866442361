import {
    POST_EPAN_STATUS_DEV_REQUEST,
    POST_EPAN_STATUS_DEV_SUCCESS,
    POST_EPAN_STATUS_DEV_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanStatusDevList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_STATUS_DEV_REQUEST:
            return {
                ...state,
                loading: true,
                epanStatusDevList: [],
            };
        case POST_EPAN_STATUS_DEV_SUCCESS:
            return {
                ...state,
                loading: false,
                epanStatusDevList: JSON.parse(action.data.response),
            };
        case POST_EPAN_STATUS_DEV_FAILED:
            return {
                ...state,
                loading: false,
                epanStatusDevList: [],
            };
        default:
            return state
    }
  }
  
  