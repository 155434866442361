import {
    POST_EPAN_TRANSACTION_STATUS_REQUEST,
    POST_EPAN_TRANSACTION_STATUS_SUCCESS,
    POST_EPAN_TRANSACTION_STATUS_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanTransStatusList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_TRANSACTION_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                epanTransStatusList: [],
            };
        case POST_EPAN_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                epanTransStatusList: action.data,
            };
        case POST_EPAN_TRANSACTION_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                epanTransStatusList: [],
            };
        default:
            return state
    }
  }
  
  