import {
    POST_EPAN_INCOMPLETE_REQUEST,
    POST_EPAN_INCOMPLETE_SUCCESS,
    POST_EPAN_INCOMPLETE_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanIncompleteList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_INCOMPLETE_REQUEST:
            return {
                ...state,
                loading: true,
                epanIncompleteList: [],
            };
        case POST_EPAN_INCOMPLETE_SUCCESS:
            return {
                ...state,
                loading: false,
                epanIncompleteList: action.data.response,
            };
        case POST_EPAN_INCOMPLETE_FAILED:
            return {
                ...state,
                loading: false,
                epanIncompleteList: [],
            };
        default:
            return state
    }
  }
  
  