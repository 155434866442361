import {
    POST_EPAN_WEB_LOGIN_REQUEST,
    POST_EPAN_WEB_LOGIN_SUCCESS,
    POST_EPAN_WEB_LOGIN_FAILED,
    POST_EPAN_WEB_DETAILS_REQUEST,
    POST_EPAN_WEB_DETAILS_SUCCESS,
    POST_EPAN_WEB_DETAILS_FAILED,
    POST_EPAN_WEB_CHECK_AADHAR_KYC_REQUEST,
    POST_EPAN_WEB_CHECK_AADHAR_KYC_SUCCESS,
    POST_EPAN_WEB_CHECK_AADHAR_KYC_FAILED,
    POST_EPAN_WEB_CHECK_AADHAR_KYC_PENDING,
    EPAN_MAKE_PAYMENT_SUCCESS,
    EPAN_MAKE_PAYMENT_REQUEST,
    EPAN_MAKE_PAYMENT_FAILED,
    EPAN_TRANSACTION_STATUS_REQUEST,
    EPAN_TRANSACTION_STATUS_PENDING,
    EPAN_TRANSACTION_STATUS_SUCCESS,
    EPAN_TRANSACTION_STATUS_FAILED,
    CHECK_USER_PAYMENT_REQUEST,
    CHECK_USER_PAYMENT_SUCCESS,
    CHECK_USER_PAYMENT_FAILED,
    CHECKPOINT_REQUEST,
    CHECKPOINT_SUCCESS,
    CHECKPOINT_FAILED
} from "../Constants/epanWebActionTypes.constants";
import {
    POST_EPAN_PAYMENT_RESEND_SUCCESS
} from "../Constants/actionTypes.constants";

export const initialStore = {
    loading: false,
    epanWebLoginList: [],
    epanWebDetailsList: [],
    eaadharFetchStatus: "",
    eaadharFetchList: [],
    epanMakePaymentList: [],
    epanTransStatusList: [],
    epanTransStatus: "",
    checkpointList: [],
};

export default (state = initialStore, action) => {
    switch(action.type) {

        case POST_EPAN_WEB_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                epanWebLoginList: [],
            };
        case POST_EPAN_WEB_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                epanWebLoginList: action.data,
            };
        case POST_EPAN_WEB_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                epanWebLoginList: [],
            };
        case POST_EPAN_WEB_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                epanWebDetailsList: [],
            };
        case POST_EPAN_WEB_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                epanWebDetailsList: action.data,
            };
        case POST_EPAN_WEB_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                epanWebDetailsList: [],
            }
        case POST_EPAN_WEB_CHECK_AADHAR_KYC_REQUEST:
            return {
                ...state,
                eaadharFetchStatus: 'requested',
                eaadharFetchList: [],
            };
        case POST_EPAN_WEB_CHECK_AADHAR_KYC_PENDING:
            return {
                ...state,
                eaadharFetchStatus: 'pending',
            };
        case POST_EPAN_WEB_CHECK_AADHAR_KYC_SUCCESS:
            return {
                ...state,
                eaadharFetchStatus: 'success',
                eaadharFetchList: action.data,
            };
        case POST_EPAN_WEB_CHECK_AADHAR_KYC_FAILED:
            return {
                ...state,
                eaadharFetchStatus: 'failed',
                errorMsg: action.error,
                eaadharFetchList: [],
            };
        case EPAN_MAKE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
                epanMakePaymentList: [],
            };
        case EPAN_MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                epanMakePaymentList: action.data,
            };
        case EPAN_MAKE_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                epanMakePaymentList: [],
            };
        case EPAN_TRANSACTION_STATUS_REQUEST:
            return {
                ...state,
                epanTransStatus: "requested",
                epanTransStatusList: [],
            };
        case EPAN_TRANSACTION_STATUS_PENDING:
            return {
                ...state,
                epanTransStatus: "pending",
            };
        case EPAN_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                epanTransStatus: "success",
                epanTransStatusList: action.data,
            };
        case EPAN_TRANSACTION_STATUS_FAILED:
            return {
                ...state,
                epanTransStatus: "failed",
                errorMsg: action.error,
                epanTransStatusList: [],
            }
        case CHECK_USER_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
                checkTransStatusList: [],
            };
        case CHECK_USER_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                checkTransStatusList: [],
            };
        case CHECK_USER_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                checkTransStatusList: [],
            };
        case CHECKPOINT_REQUEST:
            return {
                ...state,
                loading: true,
                checkpointList: [],
            };
        case CHECKPOINT_SUCCESS:
            return {
                ...state,
                loading: false,
                checkpointList: action.data,
            };
        case CHECKPOINT_FAILED:
            return {
                ...state,
                loading: false,
                checkpointList: [],
            };
        case POST_EPAN_PAYMENT_RESEND_SUCCESS:
            return {
                ...state,
                epanTransStatus: "requested",
                epanTransStatusList: [],
            };
        
        
        default:
            return state
    }
};