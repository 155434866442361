import {
    GET_SPAN_LIST_REQUEST,
    GET_SPAN_LIST_SUCCESS,
    GET_SPAN_LIST_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    spanList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_SPAN_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                spanList: [],
            };
        case GET_SPAN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                spanList: action.data.result,
            };
        case GET_SPAN_LIST_FAILED:
            return {
                ...state,
                loading: false,
                spanList: [],
            };
        default:
            return state
    }
  }
  
  