import {
    POST_SPAN_MARGIN_REQUEST,
    POST_SPAN_MARGIN_SUCCESS,
    POST_SPAN_MARGIN_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    spanMarginList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_SPAN_MARGIN_REQUEST:
            return {
                ...state,
                loading: true,
                spanMarginList: [],
            };
        case POST_SPAN_MARGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                spanMarginList: action.data,
            };
        case POST_SPAN_MARGIN_FAILED:
            return {
                ...state,
                loading: false,
                spanMarginList: [],
            };
        default:
            return state
    }
  }
  
  