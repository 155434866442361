import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import './style.css'
import style1 from '../Footer/Footer.module.scss'
import "bootstrap/dist/css/bootstrap.css";
import { OverlayTrigger } from 'react-bootstrap'
//import OverlayTrigger from "react-bootstrap/";
import Tooltip from "react-bootstrap/Tooltip";
const cardValues = [
  {
    title: "At the beginning of the month",
    value: 0,
  },
  {
    title: "Received during the month",
    value: 0,
  },
  {
    title: "Resolved during the month",
    value: 0,
  },
  {
    title: "Pending at the end of the month",
    value: 0,
  },
  {
    title: "Reasons for pendency",
    value: 0,
  },
];

export default ({ open, closeModal }) => {
  return (
    <Popup open={open} modal nested closeOnDocumentClick={false}>
      {(close) => (
        <div className="modal-container">
          {/* <button className="close" onClick={close}>
            &times;
          </button> */}
          <div className="header">Choose Your Jurisdiction   </div>
          <p class="below_title_line"></p>
          <div className="content">
            <div onClick={closeModal} className="country-box">

              <OverlayTrigger placement="left" overlay={<Tooltip>Indian citizen means holder of Indian passport but not resident in US </Tooltip>}>
                <img src='/static/images/Indian citizen.png' />
              </OverlayTrigger>
              <h6>Indian </h6>
            </div>

            <OverlayTrigger placement="bottom" overlay={<Tooltip>US Person means US residents or US citizens who reside in the US or other countries</Tooltip>} trigger={"hover"}>
              <div className="country-box">
                <a href="https://www.lotusdew.co/"  >

                  <img src='/static/images/Us citizens.png' />
                </a>
                <h6>US Person</h6>
              </div>
            </OverlayTrigger>

            <div onClick={closeModal} className="country-box">
              <img src='/static/images/others.png' />
              <h6>Rest of the World</h6>
            </div>
          </div>
          <div className={style1.complaintSection}>
            <div className={style1.heading}>
              <div>Sit back and relax</div>
              <div>You are in good hands</div>
            </div>
            <div className={style1.subTitle}>
              No. of complaints as per SEBI guidelines
            </div>
            <div className={style1.cardContainer}>
              {cardValues.map((card) => (
                <div className={style1.card}>
                  <div className={style1.cardValue}>{card.value}</div>
                  <div className={style1.cardTitle}>{card.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};