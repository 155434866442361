import {
    POST_EPAN_STATUS_REQUEST,
    POST_EPAN_STATUS_SUCCESS,
    POST_EPAN_STATUS_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanStatusList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                epanStatusList: [],
            };
        case POST_EPAN_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                epanStatusList: JSON.parse(action.data.response),
            };
        case POST_EPAN_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                epanStatusList: [],
            };
        default:
            return state
    }
  }
  
  