import {
    POST_EPAN_RESEND_UPI_REQUEST,
    POST_EPAN_RESEND_UPI_SUCCESS,
    POST_EPAN_RESEND_UPI_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
    epanResendUPIidList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_EPAN_RESEND_UPI_REQUEST:
            return {
                ...state,
                loading: true,
                epanResendUPIidList: [],
            };
        case POST_EPAN_RESEND_UPI_SUCCESS:
            return {
                ...state,
                loading: false,
                epanResendUPIidList: action.data,
            };
        case POST_EPAN_RESEND_UPI_FAILED:
            return {
                ...state,
                loading: false,
                epanResendUPIidList: [],
            };
        default:
            return state
    }
  }
  
  