import React, { useEffect, Suspense, lazy ,useRef,useState} from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { Router, Route, Switch } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.scss';
import "./fonts/MyFontsWebfontsKit.css";
import 'bootstrap/dist/css/bootstrap.css';
import { history } from './History';
import {initGA, PageView} from "./utils/tracking";
import AppLoader from './components/appLoader/appLoader';
import Popup from './components/CountryPopUp/CountryPopUp';
const AnnexureA = lazy(()=> import('./components/Annexure/Annexure-A'));
const AnnexureB = lazy(()=> import('./components/Annexure/Annexure-B'));
const GrievanceRedressal = lazy(()=> import('./components/Annexure/Grievance-Redressal'));

const Graph = lazy(() => import('./components/Graph/Graph' /* webpackChunkName: "graph" */));
const About = lazy(() => import('./components/About/About' /* webpackChunkName: "about" */));
const Layout = lazy(() => import('./components/Layout/Layout' /* webpackChunkName: "layout" */));
const Team = lazy(() => import('./components/Team/Team' /* webpackChunkName: "team" */));
const Product = lazy(() => import('./components/Product/Product' /* webpackChunkName: "product" */));
const Blog = lazy(() => import('./components/Blog/Blog' /* webpackChunkName: "blog" */));
const AssetAllocation = lazy(() => import('./components/AssetAllocation/assetAllocation' /* webpackChunkName: "asset-allocation" */));
const Disclaimer = lazy(() => import('./components/Disclaimer/Disclaimer' /* webpackChunkName: "disclaimer" */));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions/TermsAndConditions' /* webpackChunkName: "TC" */));
const RefundPolicy = lazy(() => import('./components/RefundPolicy/RefundPolicy' /* webpackChunkName: "Refund policy" */));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy' /* webpackChunkName: "Privacy policy" */));
const Test = lazy(() => import('./components/Test/test' /* webpackChunkName: "test" */));
const BlogDetails = lazy(() => import('./components/Blog/BlogComponents/BlogDetails' /* webpackChunkName: "blog-details" */));
const UserVerification = lazy(() => import('./Containers/UserVerification' /* webpackChunkName: "user-verification" */));
const CustomerSupport = lazy(() => import('./Containers/CustomerSupport' /* webpackChunkName: "user-verification" */));
const Login = lazy(() =>
  import(
   "./components/Updated Chatbot/Login/Login" /* webpackChunkName: "user-verification" */
  )
);
const AdminLogin = lazy(() =>
  import(
   "./components/Updated Chatbot/AdminLogin/AdminLogin" /* webpackChunkName: "user-verification" */
  )
);
const OnBoarding = lazy(() =>
  import(
   "./components/Updated Chatbot/MfLanding" /* webpackChunkName: "user-verification" */
  )
);
const Media = lazy(() =>
  import(
    "./components/Media/Media"
  )
);
const FinancialPlanning = lazy(() =>
  import(
    "./components/FinancialPlanning/FinancialPlanning"
  )
);
const Span = lazy(() =>
  import(
    "./components/Span/Span"
  )
);
const MarginCalc = lazy(() =>
  import(
    "./components/Margin/MarginCalc"
  )
);
const EpanLogin = lazy(() =>
  import(
    "./components/EpanLogin/EpanLogin"
  )
);
const EpanLoginDev = lazy(() =>
  import(
    "./components/EpanLoginDev/EpanLoginDev"
  )
);
const Redirect = lazy(() =>
  import(
    "./Redirect"
  )
);
const Pan = lazy(() =>
  import(
    "./components/Pan/Pan"
  )
);

const AnnulReturn = lazy(() =>
  import(
    "./components/AnnulReturn/AnnulReturn"
  )
);

function App() {
    const [open, setOpen] = useState(false);
    useEffect(() => {
    
        if(!window.localStorage.getItem('country'))setOpen(true);
        
      
    }, []);

  useEffect(() => {
    initGA();
    PageView();
    
  }, []);
  const myRef = useRef(null)
  const closeModal = () => {
    setOpen(false)
    window.localStorage.setItem('country', true);
  }

  return (
    <ParallaxProvider>
      <Provider store={store}>
        <Router history={history}>
          <div className='App'>
            <Suspense fallback={<AppLoader />}>
              <Layout myRef={myRef}>
                  <Popup open={open} closeModal={closeModal}/>
                <Switch>
                   <Route  path='/annexure-a' exact component={AnnexureA}/>
                   <Route  path='/annexure-b' exact component={AnnexureB}/>
                   <Route path='/grievance-redressal' exact component={GrievanceRedressal}/>
                   <Route path="/" exact 
                                    render={(props) => <About myRef={myRef} />} />
                  
                  {/* <Route path="/team" component={Team} /> */}
                                    <Route path="/products" component={Product} />
                                    <Route exact path="/blog" component={Blog} />
                                    <Route path="/user_verification" component={UserVerification} />
                                    <Route exact path="/blog/:title" render={(props) => <BlogDetails {...props} /> } />
                                    <Route path="/explore" component={Graph} />
                                    <Route path="/disclaimer" component={Disclaimer} />
                                    <Route path="/digilocker-test" component={Test} />
                                    <Route path="/terms-and-conditions" component={TermsAndConditions} />
                                    <Route path="/refund-policy" component={RefundPolicy} />
                                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                                    <Route path="/asset-allocation" component={AssetAllocation} />
                                    <Route path="/customer-support" component={CustomerSupport} />
                                    <Route path='/login' exact component={Login} />
                                    <Route path='/admin' exact component={AdminLogin} />
                                    <Route path='/onboarding' exact component={OnBoarding} />
                                    <Route path='/media' exact component={Media} />
                                    <Route path='/financial-planning' exact component={FinancialPlanning} />
                                    <Route path='/margin-files' exact component={Span} />
                                    <Route path='/nse-margins' exact component={MarginCalc} />
                                    <Route path='/epan-login' exact component={EpanLogin} />
                                    <Route path='/epan-login-dev' exact component={EpanLoginDev} />
                                    <Route
                                      path="/aadhar"
                                      component={Redirect}
                                    />
                                    <Route path='/epan' exact component={Pan} />
                                    <Route path='/annual-return' exact component={AnnulReturn} />
                </Switch>
              </Layout>
            </Suspense>
          </div>
        </Router>
      </Provider>
    </ParallaxProvider>
  );
}

export default App;
