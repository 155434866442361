import {
     POST_EPAN_UPDATE_DEV_REQUEST,
     POST_EPAN_UPDATE_DEV_SUCCESS,
     POST_EPAN_UPDATE_DEV_FAILED,
  } from "../Constants/actionTypes.constants";
  
  export const initialStore = {
     epanUpdateDevList: [],
    loading: false,
  };
  
  
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case  POST_EPAN_UPDATE_DEV_REQUEST:
            return {
                ...state,
                loading: true,
                 epanUpdateDevList: [],
            };
        case  POST_EPAN_UPDATE_DEV_SUCCESS:
            return {
                ...state,
                loading: false,
                 epanUpdateDevList: action.data,
            };
        case  POST_EPAN_UPDATE_DEV_FAILED:
            return {
                ...state,
                loading: false,
                 epanUpdateDevList: [],
            };
        default:
            return state
    }
  }
  
  